import React, {BaseSyntheticEvent, useState} from "react";
import {DisplayModel} from "../../../models/DisplayModel";
import {useTranslation} from "react-i18next";
import {TemplateManager} from "../../../displayTemplates/TemplateManager";
import {SimpleFormStatusAlert} from "../../../2ndlib/components/form/SimpleFormStatusAlert";
import {FormProvider, useForm} from "react-hook-form";
import {Utils} from "../../../2ndlib/utils/Utils";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InfoModal from "../../../2ndlib/components/InfoModal/InfoModal";
import {InfoModalInfo} from "../../../models/InfoModalInfo";
import useWebSocket from "react-use-websocket";
import {emitCustomEvent, useCustomEventListener} from "react-custom-events";
import {uid} from "../../../utils/Utils";
import {PublishDisplayMessageModel} from "../../../models/PublishDisplayMessageModel";

export const DashboardInlineTempleManager = (props: { display: DisplayModel, setShow: any, setActiveKey: any,setReload:any }) => {
    const {t} = useTranslation();
    const methods = useForm<any>({
        defaultValues: props.display
    });
    const [infoModal, setInfoModal] = useState<InfoModalInfo>(new InfoModalInfo());
    const [messageId, setMessageId] = useState(uid());

    const {
        sendJsonMessage,
    } = useWebSocket(process.env.REACT_APP_API_WEB_SOCKET!!, {
        onOpen: () => console.log('opened'),
        onMessage: (e) => {
            const data = JSON.parse(e.data);
            const responseType = (data.messageType);
            emitCustomEvent(responseType, data);
        },
        shouldReconnect: (closeEvent) => true,
        share: true
    });


    useCustomEventListener("PublishDisplayMessage", (data: PublishDisplayMessageModel) => {
        if (data.messageId === messageId) {
            if (data.errorMessage && data.errorMessage.trim().length > 0) {
                setInfoModal({
                    ...infoModal,
                    text: data.errorMessage,
                    title: t("errorOccurred"),
                    show: true,
                    close: true
                });
            } else {
                setInfoModal({
                    ...infoModal,
                    show: false
                });
            }
        }
    });

    const onSubmit = async (values: DisplayModel, e?: React.BaseSyntheticEvent) => {
        let values2 = JSON.parse(JSON.stringify(values)) as DisplayModel;
        values2.tpl = JSON.stringify(values2.tpl);
        delete (values2 as any)._tmp_dirty_marker;

        await Utils.simpleSubmitHandler(
            values2, methods.setError, t, "/api/v2/displays/dashboard/display")
            .then((data: any) => {
                    values.id = data.id;
                    methods.reset(values);
                    let tgt = e?.target.name;
                    if ((tgt === "saveAndPublish") || (tgt === "saveAndPublishForAllInThisGroup")) {
                        let isPublishGroup = (tgt === "saveAndPublishForAllInThisGroup");
                        methods.setValue("_tmp_dirty_marker", uid(), {shouldDirty: true})

                        sendJsonMessage({
                            messageId: messageId,
                            messageType: "PublishDisplayMessage",
                            displayId: data.id
                        });

                        setInfoModal({
                            ...infoModal,
                            show: true,
                            title: isPublishGroup ? t("transferDataToDisplays") : t("transferDataToDisplay"),
                            text: t("oneMomentPlease"),
                            close: false
                        });
                    }
                    props.setReload(uid());
                }
            ).catch((error) => {
                console.log("ERROR:::", error);
            });
    };

    const saveAndPublish = (e?: BaseSyntheticEvent) => {
        methods.handleSubmit(onSubmit, onError)(e);
    }
    const saveAndPublishForGroup = (e?: BaseSyntheticEvent) => {
        methods.handleSubmit(onSubmit, onError)(e);
    }

    const onError = (error: any) => {
        console.log("ERROR:::", error);
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit, onError)}>
                <TemplateManager showParameter={false} parameterGui={props.display.parameterGui}
                                 pageGui={props.display.pageGui}/>
                <SimpleFormStatusAlert/>


                <Button disabled={methods.formState.isSubmitting} type="button" className="me-2 mb-2"
                        onClick={(e) => {
                            if (props.setShow !== null) {
                                props.setShow(false);
                            } else {
                                props.setActiveKey(null);
                            }
                        }} variant="primary">{t("close")}</Button>
                <Button name="saveAndPublish" disabled={methods.formState.isSubmitting} type="button"
                        className="me-2 mb-2"
                        onClick={(e) => {
                            saveAndPublish(e);
                        }} variant="primary">{t("saveAndPublish")}</Button>
                <Button disabled={methods.formState.isSubmitting} type="submit" className="me-2 mb-2"
                        variant="primary">{t("save")}</Button>

                <Button name="saveAndPublishForAllInThisGroup" disabled={methods.formState.isSubmitting}
                        type="button" className="me-2 mb-2"
                        onClick={(e) => {
                            saveAndPublishForGroup(e);
                        }} variant="secondary">{t("saveAndPublishForAllInThisGroup")}</Button>
            </Form>
            <InfoModal infoModal={infoModal} setInfoModal={setInfoModal}/>
        </FormProvider>
    );


}



import {Headline} from "../../components/headline/Headline";
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import React, {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import {useLoaderData} from "react-router";
import {UserModel} from "../../models/UserModel";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import {IUserData} from "../../models/IUserData";
import {UserUtils} from "../../utils/UserUtils";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import {Utils} from "../../2ndlib/utils/Utils";
import {SimpleFormStatusAlert} from "../../2ndlib/components/form/SimpleFormStatusAlert";
import Alert from "react-bootstrap/Alert";
import axiosInstance from "../../2ndlib/api/axios";
import {SimpleInput} from "../../2ndlib/components/form/SimpleInput";
import {FormIntegratedDisplayGroupList} from "../../components/displayList/FormIntegratedDisplayGroupList";
import {SubHeadline} from "../../components/subheadline/SubHeadline";
import {AxiosResponse} from "axios";
import {ArrowLeft} from "react-bootstrap-icons";


export const User = () => {
    const {t} = useTranslation();
    const [tmpPasswd, setTmpPasswd] = useState(Utils.generatePass(15));
    const [updatePassword, setUpdatePassword] = useState(false);

    const user = useLoaderData() as UserModel;
    const auth = useAuthUser<IUserData>();
    const navigate = useNavigate();


    const methods = useForm<UserModel>({
        resolver: yupResolver(yup.object({
            id: yup.number(),
            salutation: yup.string().trim().required(),
            firstname: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("firstname")})),
            lastname: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("lastname")})),
            username: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("username")})),
            email: yup.string().email(t("error.validation.Email")).trim(),
            language: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("language")})),
            role: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("role.role")}))
        })),
        defaultValues: user
    });
    const watchShowDisplayGroups = methods.watch("role", (user.role && user.role !== "") ? user.role : "user");
    const watchLanguage = methods.watch("language", (user.language && user.language !== "") ? user.language : "de");

    const formId = methods.getValues("id");
    const isFormIdDefined = formId !== undefined && formId !== null;
    const isAuthenticated = auth !== null && auth.id !== undefined && auth.id !== null
    const isMyProfile = isFormIdDefined && isAuthenticated && formId === (auth.id * 1);
    const isAddNewUser = (!isFormIdDefined || formId === 0) && isAuthenticated;


    React.useEffect(() => {
        if (isAddNewUser ) {
            methods.setFocus("username");
        }
    });

    const resetPassword = async () => {
        let tmpPasWd = Utils.generatePass(15);
        await axiosInstance.post(`/api/v2/users/${user.id}/resetPassword`,
            {password: tmpPasWd})
            .then(function (response: AxiosResponse) {
                setTmpPasswd(response.data.password);
                setUpdatePassword(true);
            })
            .catch(function (error: any) {
                console.log("ERROR:::", error);
            });
    }

    const onSubmit = async (values: UserModel) => {
        if (values.id === undefined || values.id === 0) {
            methods.setValue("password", tmpPasswd, {
                shouldValidate: false,
                shouldDirty: false,
                shouldTouch: false
            });
        }
        await Utils.simpleSubmitHandler(values, methods.setError, t,
            "/api/v2/users").then((data:any) => {
            methods.reset(data);
        }).catch((error) => {
            // console.log("ERROR:::", error);
        });
    };

    const onError = (error: any) => {
        console.log("ERROR:::", error);
    };



    return (
        <main className="px-3">
            <Container>
                <Headline>
                    {!isAddNewUser && !isMyProfile && t("editUser")}
                    {isMyProfile && t("myProfile")}
                    {isAddNewUser && t("newUser")}
                </Headline>
                <div className={"mt-3"}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmit, onError)}>
                            <Row>
                                <Col className="col-12 col-md-6">
                                    <SimpleInput type={"text"} name={"username"} i18nKey={"username"}/>
                                </Col>
                                <Col className="col-12 col-md-6">
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t("salutation")}</Form.Label>
                                        <Form.Select {...methods.register("salutation")}>
                                            <option value="female">{t("salutationMrs")}</option>
                                            <option value="male">{t("salutationMr")}</option>
                                            <option value="none">{t("salutationNone")}</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12 col-md-6">
                                    <SimpleInput type={"text"} name={"firstname"} i18nKey={"firstname"}/>
                                </Col>
                                <Col className="col-12 col-md-6">
                                    <SimpleInput type={"text"} name={"lastname"} i18nKey={"lastname"}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12 col-md-6">
                                    <SimpleInput type={"text"} name={"email"} i18nKey={"eMailAddress"}/>
                                </Col>
                                <Col className="col-12 col-md-6">
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t("language")}</Form.Label>
                                        <Form.Select className="form-control" {...methods.register("language")}>
                                            <option value="de">{t("german")}</option>
                                            <option value="en">{t("english")}</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12 col-md-6">
                                    <SimpleInput type={"text"} name={"phone"} i18nKey={"phone"}/>
                                </Col>
                            </Row>

                            {UserUtils.isAdmin(auth) &&
                                <Row>
                                    <Col className="col-12 col-md-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("role.role")}</Form.Label>
                                            <Form.Select className="form-control" {...methods.register("role")}>
                                                <option value="user">{t("role.user")}</option>
                                                <option value="admin">{t("role.admin")}</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            }

                            {(UserUtils.isAdmin(auth) && watchShowDisplayGroups === "user" && user.displayGroups && user.displayGroups.length > 0) ?(
                                <><SubHeadline>
                                    {isMyProfile ? t("selectedDisplayGroupsAreAccessibleForMe") : t("selectedDisplayGroupsAreAccessibleForThisUser")}
                                </SubHeadline>
                                    <div className={"mb-3"}>
                                        <FormIntegratedDisplayGroupList displayGroups={user.displayGroups}
                                                                        showDisplays={true}/>
                                    </div>
                                </>):<div className={"mt-1 mb-3"}>{t("adminDisplayGroupInfo")}</div>
                            }

                            <SimpleFormStatusAlert/>

                            <Alert
                                show={(methods.formState.isSubmitSuccessful && !methods.formState.isDirty && user.id === undefined) || updatePassword}
                                variant={"info"}>
                                {updatePassword && <div>{t("passwordResetSuccessfully")}</div>}
                                {t("newUserAddedTemporaryPassword",
                                    {username: methods.getValues("username"), password: tmpPasswd})}</Alert>

                            <Button className="mb-2 me-2" disabled={methods.formState.isSubmitting} variant="outline-secondary" onClick={e => {
                                navigate(UserUtils.isAdmin(auth)?"/app/users":"/app/dashboard");
                            }}><ArrowLeft className="pointer"/></Button>
                            <Button className="mb-2 me-2" disabled={methods.formState.isSubmitting} type="submit"
                                    variant="primary">{t("save")}</Button>
                            {UserUtils.isAdmin(auth) && !isAddNewUser &&
                                <Button className="mb-2 me-2" disabled={methods.formState.isSubmitting} onClick={resetPassword} type="button"
                                        variant="outline-secondary">{t("resetPassword")}</Button>}
                        </Form>
                    </FormProvider>
                </div>
            </Container>
        </main>
    );
}

export async function loader(obj: any) {
    let id = parseInt(obj.params.id);
    return await axiosInstance.get(`/api/v2/users/${id}?widthUnAssignedDisplayGroups=true`)
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error: any) {
            throw new Error(error.response);
        });
}

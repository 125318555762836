import './App.css';
import './axios/global';
import "./i18n/config";
import {createRoutesFromElements, Route, RouterProvider} from "react-router";
import {createBrowserRouter} from "react-router-dom";
import AuthProvider from 'react-auth-kit'
import {Imprint} from "./routes/imprint/Imprint";
import {Navigation} from "./routes/navigation/Navigation";
import {ForgottenPasswordReset, loader as setPwLoader} from "./routes/password/ForgottenPasswordReset";
import {ForgottenPasswordRequest} from "./routes/password/ForgottenPasswordRequest";
import {Register} from "./routes/register/Register";
import {Login} from "./routes/login/Login";
import createStore from "react-auth-kit/createStore";
import RequireAuth from "@auth-kit/react-router/RequireAuth";
import {UserList} from "./routes/user/UserList";
import {User, loader as userLoader} from "./routes/user/User";
import {DisplayGroup, loader as displayGroupLoader} from "./routes/displayGroup/DisplayGroup";
import {Dashboard, loader as dashboardLoader} from "./routes/dashboard/Dashboard";
import {ChangePassword} from "./routes/user/ChangePassword";
import {DisplayGroupList} from "./routes/displayGroup/DisplayGroupList";
import {Display, loader as displayLoader} from "./routes/display/Display";
import {DisplayList} from "./routes/display/DisplayList";
import React from "react";
import {DisplayTemplateSelection} from "./routes/display/DisplayTemplateSelection";
import {ProtectedRoute} from "./2ndlib/components/ProtectedRoute";
import {LogView} from "./routes/logView/LogView";
import {TemplateList} from "./routes/template/TemplateList";
import {Template, loader as templateLoader} from "./routes/template/Template";


const store = createStore({
    authName: '_auth',
    authType: 'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
});


const appRoutes = [
    <Route path='/' element={<Login/>}/>,
    <Route path='/login' element={<Login/>}/>,
    <Route path='/register' element={<Register/>}/>,
    <Route path='/forget' element={<ForgottenPasswordRequest/>}/>,
    <Route path='/setpw/:token' element={<ForgottenPasswordReset/>} loader={setPwLoader}/>,
    <Route path={'/app'} element={<RequireAuth fallbackPath={'/login'}><Navigation/></RequireAuth>}>,
        <Route path='dashboard' element={<Dashboard/>} loader={dashboardLoader}/>,
        <Route path='imprint' element={<Imprint/>}/>,

        <Route path='displaygroups' element={<ProtectedRoute allowedRoles={"admin"} redirectTo={"/app/dashboard"}><DisplayGroupList/></ProtectedRoute>}/>,
        <Route path='templates' element={<ProtectedRoute allowedRoles={"admin"} redirectTo={"/app/dashboard"}><TemplateList/></ProtectedRoute>}/>,
        <Route path='displays' element={<ProtectedRoute allowedRoles={"admin"} redirectTo={"/app/dashboard"}><DisplayList/></ProtectedRoute>}/>,
        <Route path='display/template/select' element={<ProtectedRoute allowedRoles={"admin"} redirectTo={"/app/dashboard"}><DisplayTemplateSelection/></ProtectedRoute>}/>,
        <Route path='users' element={<ProtectedRoute allowedRoles={"admin"} redirectTo={"/app/dashboard"}><UserList/></ProtectedRoute>}/>,
        <Route path='displaygroup/:id' element={<ProtectedRoute allowedRoles={"admin"} redirectTo={"/app/dashboard"}><DisplayGroup/></ProtectedRoute>} loader={displayGroupLoader}/>,
        <Route path='display/:id' element={<ProtectedRoute allowedRoles={"admin"} redirectTo={"/app/dashboard"}><Display/></ProtectedRoute>} loader={displayLoader}/>,
        <Route path='template/:id' element={<ProtectedRoute allowedRoles={"admin"} redirectTo={"/app/dashboard"}><Template/></ProtectedRoute>} loader={templateLoader}/>,
        <Route path='logview' element={<ProtectedRoute allowedRoles={"admin"} redirectTo={"/app/dashboard"}><LogView/></ProtectedRoute>}/>,


        <Route path='user/:id' element={<User/>} loader={userLoader}/>,
        <Route path='changePassword' element={<ChangePassword/>}/>
    </Route>
];


const routes = createRoutesFromElements(appRoutes);
const router = createBrowserRouter(routes);

document.title = "Schauf GmbH";

export const App = () => (
    <AuthProvider store={store}>
        <RouterProvider router={router}/>
    </AuthProvider>
);

import {Headline} from "../../components/headline/Headline";
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import React from "react";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import {useLoaderData} from "react-router";

import {useNavigate} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {Utils} from "../../2ndlib/utils/Utils";
import {SimpleFormStatusAlert} from "../../2ndlib/components/form/SimpleFormStatusAlert";
import {SimpleInput} from "../../2ndlib/components/form/SimpleInput";
import {AxiosResponse} from "axios";
import axiosInstance from "../../2ndlib/api/axios";
import {TemplateModel} from "../../models/TemplateModel";
import {SimpleTextArea} from "../../2ndlib/components/form/SimpleTextArea";
import {ArrowLeft} from "react-bootstrap-icons";


export const Template = () => {
    const {t} = useTranslation();
    let template = useLoaderData() as TemplateModel;
    const navigate = useNavigate();
    const methods = useForm<any>({
        resolver: yupResolver(yup.object({
            id: yup.number().nullable(),
            name: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("name")})),
            pageGui: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("pageGui")})),
            parameterGui: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("parameterGui")})),
            tpl: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("tpl")})),
        })),
        defaultValues: template
    });

    React.useEffect(() => {
        if (methods.getValues("id") === undefined) {
            methods.setFocus("name");
        }
    },[]);

    const onSubmit = async (values: any) => {
        console.log("VALUES:::", values);
        await Utils.simpleSubmitHandler(values, methods.setError, t,
            "/api/v2/templates").then((data:any) => {
            methods.reset(data);
        }).catch((error) => {
            // console.log("ERROR:::", error);
        });
    };

    const onError = (error: any) => {
        console.log("ERROR:::", error);
    };

    return (
        <main className="px-3">
            <Container>
                <Headline>
                    {methods.getValues("id") !== null && t("editTemplate")}
                    {methods.getValues("id") === null && t("newTemplate")}
                </Headline>
                <div className={"mt-3"}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmit, onError)}>
                            <Row>
                                <Col className="col-12">
                                    <SimpleInput type={"text"} name={"name"} i18nKey={"nameTemplate"}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <SimpleTextArea name={"parameterGui"} i18nKey={"parameterGui"}/>

                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <SimpleTextArea name={"pageGui"} i18nKey={"pageGui"}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <SimpleTextArea name={"thumbnailGui"} i18nKey={"thumbnailGui"}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <SimpleTextArea name={"tpl"} i18nKey={"initialValue"}/>
                                </Col>
                            </Row>
                            <SimpleFormStatusAlert/>
                            <div className="mt-3">
                                <Button disabled={methods.formState.isSubmitting} className="me-2" variant="outline-secondary" onClick={e => {
                                    navigate("/app/templates");
                                }}><ArrowLeft className="pointer"/></Button>
                                <Button disabled={methods.formState.isSubmitting} type="submit" className="btn"
                                        variant="primary">{t("save")}</Button>
                            </div>
                        </Form>
                    </FormProvider>
                </div>
            </Container>
        </main>
    );
}

export async function loader(obj: any) {
    let id = parseInt(obj.params.id);
    return await axiosInstance.get(`/api/v2/templates/${id}`)
        .then(function (response: AxiosResponse<any, any>) {
            return response.data;
        }).catch(function (error: any) {
            console.error("ERROR:::", error);
            throw new Error(error.response);
        });
}

import {Accordion} from "react-bootstrap";
import React, {useState} from "react";
import {DisplayModel} from "../../models/DisplayModel";
import {DashboardDisplayItem} from "./dashboard/DashboardDisplayItem";


export const DashboardDisplayList = (props: {displayGroup: any,setReload:any}) => {
    const [activeKey, setActiveKey] = useState(null);
    return <Accordion activeKey={activeKey}>
        {props.displayGroup.displays.map((display:DisplayModel) => (
            <DashboardDisplayItem key={display.id} display={display} setActiveKey={setActiveKey} activeKey={activeKey} setReload={props.setReload}/>
        ))}
    </Accordion>
}


import React from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import logo from '../../schauf_logo.svg';
import logoWide from '../../schauf_logo_breit.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import avatar from './avatar.jpg';
import {useTranslation} from 'react-i18next';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import {IUserData} from "../../models/IUserData";
import {UserUtils} from "../../utils/UserUtils";


export const  Navigation = () => {
    const {t, i18n} = useTranslation();
    const signOut = useSignOut()
    const auth = useAuthUser<IUserData>()

    const navigate = useNavigate();
    return (
        <div className={"d-flex flex-column min-vh-100"}>
            <header className="p-3 mb-3 border-bottom">
                <div className="container">
                    <div
                        className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                        <img className="pointer" src={UserUtils.isAdmin(auth)?logo:logoWide} alt="" onClick={e => {
                            navigate('/app/dashboard');
                        }} style={{width: UserUtils.isAdmin(auth)?"30px":"125px"}}/>
                         <ul className="ps-md-4 nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                             {UserUtils.isAdmin(auth) && <>
                                 <li className="nav-item"><NavLink className="nav-link"
                                                                   to="/app/dashboard">{t("dashboard")}</NavLink></li>
                                 <li className="nav-item"><NavLink className="nav-link"
                                                                   to="/app/displaygroups">{t("displayGroups")}</NavLink>
                                 </li>

                                 <li className="nav-item"><NavLink className="nav-link"
                                                                   to="/app/displays">{t("displays")}</NavLink>
                                 </li>
                                 <li className="nav-item"><NavLink className="nav-link"
                                                                   to="/app/templates">{t("templates")}</NavLink>
                                 </li>

                                 <li className="nav-item"><NavLink className="nav-link"
                                                                   to="/app/users">{t("users")}</NavLink>
                                 </li>
                                 <li className="nav-item"><NavLink className="nav-link"
                                                                   to="/app/logview">{t("server")}</NavLink>
                                 </li>
                             </>}

                         </ul>

                        <Dropdown className="text-end">
                            <Dropdown.Toggle variant="white">
                                <img src={avatar} alt="" width="32" height="32"
                                     className="rounded-circle"/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => {
                                    console.log(auth);
                                    navigate('/app/user/' + auth?.id);
                                }}>{t('myProfile')}</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    navigate('/app/changePassword');
                                }}>{t('changePassword')}</Dropdown.Item>
                                <Dropdown.Divider/>
                                <Dropdown.Item onClick={() => {
                                    signOut();
                                    navigate('/login');
                                }}>{t('signOut')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </header>
            <div className={"flex-grow-1"}>
                <Outlet/>
            </div>
            <footer className=" py-3 mt-5 small border-top">
                <div className="container d-flex flex-wrap justify-content-between align-items-center">
                    <p className="col-md-4 mb-0 text-body-secondary"></p>
                    <ul className="nav col-md-4 justify-content-end">

                        <li className="nav-item"><NavLink className="px-2 nav-link"
                                                          to="imprint">{t('imprint')}</NavLink></li>
                    </ul>
                </div>
            </footer>
        </div>
    )
}

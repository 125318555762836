import React  from "react";
import {DisplayModel} from "../../../models/DisplayModel";
import {useTranslation} from "react-i18next";
import Modal from "react-bootstrap/Modal";
import {DashboardInlineTempleManager} from "./DashboardInlineTempleManager";
import {ThumbnailManager} from "../../../displayTemplates/ThumbnailManager";

export const DashboardDisplayTileItem =  (props: { display: DisplayModel,setReload:any }) => {
    const {t} = useTranslation();
    const [show, setShow] = React.useState(false);
    return (
        <div className="card shadow-sm" >
            <div onClick={() => setShow(true)} className={"pointer display-preview-tile-all bg-body-secondary w-100 overflow-hidden"} style={{height:"225px"}}>
                <div className="w-100 overflow-hidden" style={{height:"210px"}}><ThumbnailManager display={props.display}/></div>
            </div>
            <div className="card-body">
                <div className="card-text">{props.display.name}</div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mt-3 btn-group">
                        <button type="button" onClick={() => setShow(true)}
                                className="btn btn-sm btn-outline-secondary">{t("editDisplay")}
                        </button>
                    </div>

                </div>
            </div>

        <Modal show={show} fullscreen={true} onHide={() => {
            setShow(false);
        }}>
            <Modal.Header closeButton>
                <Modal.Title>{props.display.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DashboardInlineTempleManager display={props.display} setShow={setShow} setActiveKey={null} setReload={props.setReload}/>
            </Modal.Body>
        </Modal>
    </div>);
}


